import React, { useEffect, useState } from 'react'
import Navbar from './Navbar';
import { Country, State, City } from "country-state-city";
import { Dropdown } from 'bootstrap';

const Form2 = () => {
    const [formData, setFormData] = useState({
        item_id: '',
        item_category: '',
        item_name: '',
        hsn_code: '',
        item_location: [
            {
                city: '',
                state: '',
                country: ''
            }
        ],
        item_vendor: []
    });

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [dropdownOpen, setDropDownOpen] = useState(false);

    const vendorData =[
        { id: 1, name: 'Vendor 1', contact: '123-456-7890', email: 'entity1@example.com', address: 'House Number: xyz', country: 'India', state: 'Haryana', city: 'Gurgaon' },
        { id: 2, name: 'Vendor 2', contact: '234-567-8901', email: 'entity2@example.com', address: 'House Number: xyz', country: 'India', state: 'Haryana', city: 'Gurgaon' },
        { id: 3, name: 'Vendor 3', contact: '345-678-9012', email: 'entity3@example.com', address: 'House Number: xyz', country: 'India', state: 'Haryana', city: 'Gurgaon' },
        { id: 4, name: 'Vendor 4', contact: '456-789-0123', email: 'entity4@example.com', address: 'House Number: xyz', country: 'India', state: 'Haryana', city: 'Gurgaon' },
        { id: 5, name: 'Vendor 5', contact: '567-890-1234', email: 'entity5@example.com', address: 'House Number: xyz', country: 'India', state: 'Haryana', city: 'Gurgaon' },
    ];

    // Get the list of countries on component mount
    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const newItemLocations = [...formData.item_location];

        newItemLocations[index] = {
            ...newItemLocations[index],
            [name]: value
        };

        setFormData((prevData) => ({
            ...prevData,
            item_location: newItemLocations
        }));

        if (name === 'country') {
            const selectedCountry = value;
            const statesList = State.getStatesOfCountry(selectedCountry);
            setStates(statesList);
            setCities([]); // Reset cities when country is changed
        }

        if (name === 'state') {
            const selectedState = value;
            const selectedCountry = formData.item_location[index].country;
            const citiesList = City.getCitiesOfState(selectedCountry, selectedState);
            setCities(citiesList);
        }
    };

    const handleVendorChange= (vendorID) => {
        setFormData((prevData) => {
            const newVendors = prevData.item_vendor.includes(vendorID)
            ? prevData.item_vendor.filter(id => id !== vendorID)
            : [...prevData.item_vendor, vendorID]; 

            return { ...prevData, item_vendor: newVendors };
        })
    }

    const addItemRow = () => {
        setFormData((prevData) => ({
            ...prevData,
            item_location: [
                ...prevData.item_location,
                {
                    city: '',
                    state: '',
                    country: ''
                }
            ]
        }));
    };

    const removeItemRow = (index) => {
        const newItems = [...formData.item_location];
        newItems.splice(index, 1);
        setFormData((prevData) => ({
            ...prevData,
            item_location: newItems
        }));
    };

    const toggleDropdown = () => {
        setDropDownOpen(!dropdownOpen);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("form data submitted", formData);
    };

    return (
        <>
            <Navbar />
            <div className='container pt-3'>
                <h2>Product FORM</h2>
                <form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
                    <div className='form-group'>
                        <label htmlFor='Item_id'>Product Id:</label>
                        <input
                            type='number'
                            id='item_id'
                            name='item_id'
                            value={formData.item_id}
                            onChange={(e) => setFormData({ ...formData, item_id: e.target.value })}
                            className='form-control'
                            placeholder='Enter Item Id'
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='item_category'>Product Category:</label>
                        <input
                            type='text'
                            id='item_category'
                            name='item_category'
                            value={formData.item_category}
                            onChange={(e) => setFormData({ ...formData, item_category: e.target.value })}
                            className='form-control'
                            placeholder='Enter Item Category'
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='item_name'>Product Name:</label>
                        <input
                            type='text'
                            id='item_name'
                            name='item_name'
                            value={formData.item_name}
                            onChange={(e) => setFormData({ ...formData, item_name: e.target.value })}
                            className='form-control'
                            placeholder='Enter Item Name'
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='hsn_code'>HSN Code:</label>
                        <input
                            type='number'
                            id='hsn_code'
                            name='hsn_code'
                            value={formData.hsn_code}
                            onChange={(e) => setFormData({ ...formData, hsn_code: e.target.value })}
                            className='form-control'
                            placeholder='Enter HSN Code'
                        />
                    </div>

                    <div className="d-flex justify-content-between align-items-center pt-3">
                        <h4 className="me-3">Product Location</h4>
                        <h3 className="me-3" onClick={addItemRow} style={{ cursor: 'pointer' }}>
                            <i className="bi bi-plus-circle-fill"></i>
                        </h3>
                    </div>

                    {formData.item_location.map((item, index) => (
                        <div key={index}>
                            {index > 0 && <hr style={{ border: '1px solid #222', margin: '20px 0', width: '100%' }} />}

                            <div className='d-flex align-items-center mb-3'>
                                <div className='flex-grow-1'>
                                    <div className='row'>
                                        <div className='col'>
                                            <select
                                                className='form-control'
                                                name='country'
                                                value={item.country}
                                                onChange={(e) => handleChange(e, index)}
                                            >
                                                <option value="">Select Country</option>
                                                {countries.map((country) => (
                                                    <option key={country.isoCode} value={country.isoCode}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='col'>
                                            <select
                                                className='form-control'
                                                name='state'
                                                value={item.state}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={!item.country}
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state) => (
                                                    <option key={state.isoCode} value={state.isoCode}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='col'>
                                            <select
                                                className='form-control'
                                                name='city'
                                                value={item.city}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={!item.state}
                                            >
                                                <option value="">Select City</option>
                                                {cities.map((city) => (
                                                    <option key={city.name} value={city.name}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {index > 0 && (
                                    <div className='ms-3'>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => removeItemRow(index)}
                                        >
                                            <i className="bi bi-trash"></i>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}

                    <div className='form-group'>
                        <label htmlFor='item_vendor'>Item Vendor:</label>
                        <div className='dropdown'>
                            <button
                                className="btn btn-secondary dropdown-toggle" 
                                type="button" 
                                onClick={toggleDropdown}
                                aria-expanded={dropdownOpen}
                            >
                                {formData.item_vendor.length === 0 ? 'Select Vendor' : `${formData.item_vendor.length} Selected`}
                            </button>
                            {dropdownOpen && (
                                <div className="dropdown-menu" style={{ display: 'block' }}>
                                {vendorData.map(entity => (
                                    <div key={entity.id} className="dropdown-item form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={entity.id}
                                            checked={formData.item_vendor.includes(entity.id)}
                                            onChange={() => handleVendorChange(entity.id)}
                                        />
                                        <label className="form-check-label">
                                            {entity.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            )}
                        </div>
                    </div>

                    {formData.item_vendor.length > 0 && (
                        <div className='mt-4'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Vendor Name</th>
                                        <th>Contact Number</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                        <th>Country</th>
                                        <th>State</th>
                                        <th>City</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {formData.item_vendor.map((entityId) => {
                                        const selectedEntity = vendorData.find(entity => entity.id === entityId);
                                        return (
                                            <tr key={selectedEntity.id}>
                                                <td>{selectedEntity.name}</td>
                                                <td>{selectedEntity.contact}</td>
                                                <td>{selectedEntity.email}</td>
                                                <td>{selectedEntity.address}</td>
                                                <td>{selectedEntity.country}</td>
                                                <td>{selectedEntity.state}</td>
                                                <td>{selectedEntity.city}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <button type='submit' className='btn btn-primary mt-3'>Submit</button>
                </form>
            </div>
        </>
    )
}

export default Form2;
