import React, { useState } from 'react';
import Select from 'react-select';
import Navbar from './Navbar';

const options = [
    { value: 'a1', label: 'a1' },
    { value: 'a2', label: 'a2' },
    { value: 'a3', label: 'a3' },
    { value: 'a4', label: 'a4' },
    { value: 'a5', label: 'a5' },
    { value: 'a6', label: 'a6' },
    { value: 'a7', label: 'a7' },
    { value: 'a8', label: 'a8' },
    { value: 'a9', label: 'a9' },
    { value: 'a10', label: 'a10' }
  ];
  
  const tableData = {
    a1: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ],
    a2: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ],
    a3: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ],
    a4: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ],
    a5: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ],
    a6: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ],
    a7: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ],
    a8: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ],
    a9: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ],
    a10: [
      { item: 1, description: '1', quantity: '$1',vendor_name: 'xty',item_price:'asd'  },
      { item: 2, description: '2', quantity: '$1.5',vendor_name: 'xty',item_price:'asd'  }
    ]
  
  };

const Form4 = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [showDetails, setShowDetails] = useState(false);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedRows([]);
    setInputValues({});
    setShowDetails(false);
  };

  const handleCheckboxChange = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(row)) {
        return prevSelectedRows.filter((r) => r !== row);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const handleInputChange = (e, item) => {
    const { value } = e.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [item.item]: value
    }));
  };

  const handleSubmit = () => {
    console.log("Selected Rows:", selectedRows);
    console.log("Input Values:", inputValues);
    alert("Submitted successfully!");
  };

  const dataToDisplay = selectedOption ? tableData[selectedOption.value] : [];

  return (
    <>
      <Navbar />
      <div className='container pt-3'>
      <h2>PO FORM</h2>
        <div className='form-group'>
          <Select
            id='select_item'
            options={options}
            onChange={handleChange}
            isSearchable
            className="form-control"
            placeholder="Select an option..."
          />
        </div>

        {selectedOption && dataToDisplay.length > 0 && (
          <div className='table-container'>
            <h2>Details for: {selectedOption.label}</h2>
            <table className="table table-hover">
              <thead className='thead-dark'>
                <tr>
                  <th>Select</th>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Vendor Name</th>
                  <th>Item Price</th>
                </tr>
              </thead>
              <tbody>
                {dataToDisplay.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </td>
                    <td>{item.item}</td>
                    <td>{item.description}</td>
                    <td>{item.quantity}</td>
                    <td>{item.vendor_name}</td>
                    <td>{item.item_price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              className='btn btn-primary'
              onClick={() => setShowDetails(true)}
              disabled={selectedRows.length === 0}
            >
              Show Details
            </button>
          </div>
        )}

        {showDetails && selectedRows.length > 0 && (
          <div className='details-container'>
            <h2>Selected Details</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Vendor Name</th>
                  <th>Item Price</th>
                  <th>Input Value</th>
                </tr>
              </thead>
              <tbody>
                {selectedRows.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item}</td>
                    <td>{item.description}</td>
                    <td>{item.quantity}</td>
                    <td>{item.vendor_name}</td>
                    <td>{item.item_price}</td>
                    <td>
                      <input
                        type="text"
                        value={inputValues[item.item] || ''}
                        onChange={(e) => handleInputChange(e, item)}
                        placeholder="Enter value..."
                        className="form-control"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {showDetails && (
          <div className='submit-container'>
            <button className='btn btn-success' onClick={handleSubmit}>
              Submit
            </button>
          </div>
        )}

        {!selectedOption && (
          <div className='no-selection'>
            <p>Please select an option to see the details.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Form4;
