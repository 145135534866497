// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-add{
    margin: 10px 0;
    border: 1px solid black;
    padding: 5px 15px;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/form.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,uBAAuB;IACvB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".item-add{\r\n    margin: 10px 0;\r\n    border: 1px solid black;\r\n    padding: 5px 15px;\r\n    border-radius: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
