import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';
import './form.css';
const Form1 = () => {
    const [formData, setFormData] = useState({
        rfp_no: '',
        date: '',
        customer_name: '',
        customer_rfp_no: '',
        project_name: '',
        project_description: '',
        due_date: '',
        items: [
          {
            item_name: '',
            item_description: '',
            item_quantity: '',
            target_item_price: ''
          }
        ],
    });

    const handleChange = (e, index) => {
        const { name, value } = e.target;

        if (typeof index === 'undefined') {
            
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        } else {
            
            const newItems = [...formData.items];
            newItems[index][name] = value;
            setFormData((prevData) => ({
                ...prevData,
                items: newItems
            }));
        }
    };

    const addItemRow = () => {
        setFormData((prevData) => ({
            ...prevData,
            items: [
                ...prevData.items,
                {
                    item_name: '',
                    item_description: '',
                    item_quantity: '',
                    target_item_price: ''
                }
            ]
        }));
    };

    const removeItemRow = (index) => {
        const newItems = [...formData.items];
        newItems.splice(index, 1);
        setFormData((prevData) => ({
            ...prevData,
            items: newItems
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted", formData);
    };

    return (
        <>
            <Navbar />

            <div className='container pt-3'>
                <h2>RFP FORM</h2>
                <form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
                    <div className="form-group">
                        <label htmlFor="rfp_no">RFP Number:</label>
                        <input
                            type="number"
                            id="rfp_no"
                            name="rfp_no"
                            value={formData.rfp_no}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter RFP Number"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="customer_name">Customer Name:</label>
                        <select 
                            type="text"
                            id="customer_name"
                            name="customer_name"
                            value={formData.customer_name}
                            onChange={handleChange}
                            className="form-control"
                        >
                            <option value="">Select Customer Name</option>
                            <option value="1">Name 1</option>
                            <option value="2">Name 2</option>
                            <option value="3">Name 3</option>
                            <option value='4'>Name 4</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="customer_rfp_no">Customer RFP Number:</label>
                        <input
                            type="number"
                            id="customer_rfp_no"
                            name="customer_rfp_no"
                            value={formData.customer_rfp_no}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter Customer RFP Number"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="date">RFP Date:</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="project_name">Project Name:</label>
                        <input 
                            type="text"
                            id="project_name"
                            name="project_name"
                            value={formData.project_name}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter Project Name"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="project_description">Project Description:</label>
                        <textarea 
                            id="project_description"
                            name="project_description"
                            value={formData.project_description}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter Project Description"
                        />
                    </div>

                    <div className='item-add'>
                    <div className="d-flex justify-content-between align-items-center pt-3">
                        <h4 className="me-3">PRODUCTS</h4>
                        <h3 className="me-3" onClick={addItemRow} style={{ cursor: 'pointer' }}>
                            <i className="bi bi-plus-circle-fill"></i>
                        </h3>
                    </div>

                    
                    {formData.items.map((item, index) => (
                        <div key={index}>
                        {index > 0 && <hr style={{ border: '1px solid #222', margin: '20px 0', width: '100%' }} />}
                        
                        <div className='d-flex align-items-center mb-3'>
                            <div className='flex-grow-1'>
                                <div className='row'>
                                    <div className="col">
                                        <label htmlFor={`item_name_${index}`}>Product Name:</label>
                                        <input
                                            type="text"
                                            id={`item_name_${index}`}
                                            name="item_name"
                                            value={item.item_name}
                                            onChange={(e) => handleChange(e, index)}
                                            className="form-control"
                                            placeholder="Enter Item Name"
                                        />
                                    </div>

                                    <div className="col">
                                        <label htmlFor={`item_description_${index}`}>Product Description:</label>
                                        <input
                                            type="text"
                                            id={`item_description_${index}`}
                                            name="item_description"
                                            value={item.item_description}
                                            onChange={(e) => handleChange(e, index)}
                                            className="form-control"
                                            placeholder="Enter Item Description"
                                        />
                                    </div>
                    
                                    <div className="col">
                                        <label htmlFor={`item_quantity_${index}`}>Product Quantity:</label>
                                        <input
                                            type="number"
                                            id={`item_quantity_${index}`}
                                            name="item_quantity"
                                            value={item.item_quantity}
                                            onChange={(e) => handleChange(e, index)}
                                            className="form-control w-60"
                                            placeholder="Enter Item Quantity"
                                        />
                                    </div>
                    
                                    <div className="col">
                                        <label htmlFor={`target_item_price_${index}`}>Target Product Price:</label>
                                        <input
                                            type="number"
                                            id={`target_item_price_${index}`}
                                            name="target_item_price"
                                            value={item.target_item_price}
                                            onChange={(e) => handleChange(e, index)}
                                            className="form-control w-60"
                                            placeholder="Enter Target Item Price"
                                        />
                                    </div>

                                </div>
                            </div>
                    
                            {index > 0 && (
                                <div className="ms-3">
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => removeItemRow(index)}
                                    >
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    
                    ))}
                    </div>


                    <div className="form-group">
                        <label htmlFor="due_date">Due Date:</label>
                        <input
                            type="date"
                            id="due_date"
                            name="due_date"
                            value={formData.due_date}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary mt-3">Submit</button>
                </form>
            </div>
        </>    
    );
}

export default Form1;
