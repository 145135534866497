import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';

const Form5 = () => {

    const [formData, setFormData] =  useState({
        due_date: ''
    });

    const [productTable, setProductTable] = useState([
        { id: 1, name: 'Product 1', description: 'Description 1', Quantity: '10', quantity: '', price: '', remarks: ''},
        { id: 2, name: 'Product 2', description: 'Description 2', Quantity: '15', quantity: '', price: '', remarks: ''},
        { id: 3, name: 'Product 3', description: 'Description 3', Quantity: '20', quantity: '', price: '', remarks: ''},
        { id: 4, name: 'Product 4', description: 'Description 4', Quantity: '25', quantity: '', price: '', remarks: ''},
    ]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleProductChange = (index, e) => {
        const { name, value } = e.target;
        const updatedTable = [...productTable];
        updatedTable[index][name] = value;
        setProductTable(updatedTable);
    };

    const rfp_number = 7;

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data: ", formData);
        console.log("Product Table Data: ", productTable);
    };

    return (
        <>
            <Navbar />
            <div className='container pt-3'>
                <form onSubmit={handleSubmit}>
                    <div className='d-flex align-items-center mb-3 mt-4'>
                        <div className='flex-grow-1'>
                            <div className='row'>
                                <div className='col'>
                                    <h4>RFP Number: {rfp_number}</h4>
                                </div>
                                <div className='col'>
                                    <div className="form-group row">
                                        <label htmlFor="due_date" className="col-sm-2 col-form-label">Due Date:</label>
                                        <div className="col-sm-9">
                                            <input 
                                            type="date" 
                                            className="form-control" 
                                            id="due_date" 
                                            name="due_date"
                                            value={formData.due_date}
                                            onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4'>
                                <table className="table table-bordered mt-3">
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Product Description</th>
                                            <th>Product Quantity</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productTable.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>{item.description}</td>
                                                <td>{item.Quantity}</td>
                                                <td>
                                                    <input 
                                                        type='number'
                                                        name='quantity'
                                                        value={item.quantity}
                                                        onChange={(e) => handleProductChange(index, e)}
                                                        className='form-control'
                                                        placeholder='Enter Quantity'
                                                    />   
                                                </td>
                                                <td>
                                                    <input 
                                                        type='number'
                                                        name='price'
                                                        value={item.price}
                                                        onChange={(e) => handleProductChange(index, e)}
                                                        className='form-control'
                                                        placeholder='Enter Price'
                                                    />   
                                                </td>
                                                <td>
                                                    <input 
                                                        type='text'
                                                        name='remarks'
                                                        value={item.remarks}
                                                        onChange={(e) => handleProductChange(index, e)}
                                                        className='form-control'
                                                        placeholder='Enter Remarks'
                                                    />   
                                                </td> 
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <button type='submit' className="btn btn-primary">Submit</button>
                </form>
            </div>
        </>
    );
}

export default Form5;
