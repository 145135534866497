import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar navbar-dark bg-dark">
        <ul className="nav justify-content-end">
            <li className="nav-item">
                <Link className="nav-link active" to='/'>PO</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link active" to='/Form1'>RFP</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link active" to='/Form2'>Product</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link active" to='/Form3'>RFQ</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link active" to='/Form5'>Mail RFQ</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link active" to='/Practise1'>Quotation</Link>
            </li>
        </ul>
    </nav>
  );
}

export default Navbar;