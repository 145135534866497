import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { Modal, Button } from 'react-bootstrap';

const Practise1 = () => {
    const [rfpData, setRfpData] = useState([
        {
            rfp_number: 1,
            due_date: '25-11-2024',
            customer_name: 'Name 1',
            project_name: 'project 1',
            products: [
                { 
                    name: 'Product A1', 
                    description: 'Description A1', 
                    quantity: '5', 
                    targetPrice: '500',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                }
            ]
        },
        {
            rfp_number: 2,
            due_date: '15-12-2024',
            customer_name: 'Name 2',
            project_name: 'project 2',
            products: [
                { 
                    name: 'Product B1', 
                    description: 'Description B1', 
                    quantity: '10', 
                    targetPrice: '1000',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                },
                { 
                    name: 'Product B2', 
                    description: 'Description B2', 
                    quantity: '15', 
                    targetPrice: '1500',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                }
            ]
        },
        {
            rfp_number: 3,
            due_date: '13-10-2024',
            customer_name: 'Name 3',
            project_name: 'project 3',
            products: [
                { 
                    name: 'Product C1', 
                    description: 'Description C1', 
                    quantity: '20', 
                    targetPrice: '2000',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                },
                { 
                    name: 'Product C2', 
                    description: 'Description C2', 
                    quantity: '25', 
                    targetPrice: '2500',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                },
                { 
                    name: 'Product C3', 
                    description: 'Description C3', 
                    quantity: '30', 
                    targetPrice: '3000',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                }
            ]
        },
        {
            rfp_number: 4,
            due_date: '10-11-2024',
            customer_name: 'Name 4',
            project_name: 'project 4',
            products: [
                { 
                    name: 'Product D1', 
                    description: 'Description D1', 
                    quantity: '35', 
                    targetPrice: '3500',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                },
                { 
                    name: 'Product D2', 
                    description: 'Description D2', 
                    quantity: '40', 
                    targetPrice: '4000',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                },
                { 
                    name: 'Product D3', 
                    description: 'Description D3', 
                    quantity: '45', 
                    targetPrice: '4500',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                },
                { 
                    name: 'Product D4', 
                    description: 'Description D4', 
                    quantity: '50', 
                    targetPrice: '5000',
                    vendors: [
                        { quantity: '10', price: '100', remarks: 'Good' },
                        { quantity: '20', price: '200', remarks: 'Bad' },
                        { quantity: '30', price: '300', remarks: 'Average' }
                    ]
                }
            ]
        }
    ]);

    useEffect(() => {
        const sortedData = [...rfpData].sort((a, b) => {
            const dateA = new Date(a.due_date.split('-').reverse().join('-'));
            const dateB = new Date(b.due_date.split('-').reverse().join('-'));
            return dateA - dateB;
        });
        setRfpData(sortedData);
    }, []);

    const [selectedRfpNumber, setSelectedRfpNumber] = useState("");
    const [filteredRfpData, setFilteredRfpData] = useState([]);
    const [selectedCustomerName, setSelectedCustomerName] = useState("");
    const [selectedProjectNumber, setSelectedProjectNumber] = useState("");
    const [selectedProjectDate, setSelectedProjectDate] = useState("");


    const handleRfpNumberChange = (e) => {
        const selectedNumber = e.target.value;
        setSelectedRfpNumber(selectedNumber);
        filterRfpData(selectedNumber, selectedCustomerName, selectedProjectNumber, selectedProjectDate);
    };
    
    const filterRfpData = (rfpNumber, customerName, projectName, dueDate) => {
        let filteredData = rfpData;

        if (rfpNumber) {
            filteredData = filteredData.filter(rfp => rfp.rfp_number.toString() === rfpNumber);
        }
        if (customerName) {
            filteredData = filteredData.filter(rfp => rfp.customer_name === customerName);
        }
        if (projectName) {
            filteredData = filteredData.filter(rfp => rfp.project_name === projectName);
        }
        if (dueDate) {
            filteredData = filteredData.filter(rfp => rfp.due_date === dueDate);
        }

        setFilteredRfpData(filteredData);
    };
    
    const handleCustomerNameChange = (e) => {
        const customerName = e.target.value;
        setSelectedCustomerName(customerName);
        filterRfpData(selectedRfpNumber, customerName, selectedProjectNumber, selectedProjectDate);
    };
    
    const handleProjectNumberChange = (e) => {
        const projectNumber = e.target.value;
        setSelectedProjectNumber(projectNumber);
        filterRfpData(selectedRfpNumber, selectedCustomerName, projectNumber, selectedProjectDate);
    };
    
    const handleProjectDateChange = (e) => {
        const projectDate = e.target.value;
        setSelectedProjectDate(projectDate);
        filterRfpData(selectedRfpNumber, selectedCustomerName, selectedProjectNumber, projectDate);
    };
    

    const [formValues, setFormValues] = useState({});
    const [selectedRfp, setSelectedRfp] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    }

    const handleQuotation = (rfp) => {
        setSelectedRfp(rfp); 
        setShowModal(true);  
    }; 

    const handleInputChange = (e, rfpIndex, productIndex) => {
        const { name, value } = e.target;
        const newFormValues = { ...formValues };

        if (!newFormValues[rfpIndex]) {
            newFormValues[rfpIndex] = {};
        }

        if (!newFormValues[rfpIndex][productIndex]) {
            newFormValues[rfpIndex][productIndex] = {
                qty: '',
                price: '',
                total: ''
            };
        }

        newFormValues[rfpIndex][productIndex][name] = value;

        
        if (name === 'qty' || name === 'price') {
            const qty = newFormValues[rfpIndex][productIndex]['qty'] || 0;
            const price = newFormValues[rfpIndex][productIndex]['price'] || 0;
            newFormValues[rfpIndex][productIndex]['total'] = qty * price;
        }

        setFormValues(newFormValues);
    };

    const handleSubmit = () => {
        console.log('Submited');    
    };

    return (
        <>
            <Navbar />
            <div className='container pt-3'>

                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="customer_name">Customer Name</label>
                        <select
                            className="form-control"
                            id="customer_name"
                            name="customer_name"
                            value={selectedCustomerName}
                            onChange={handleCustomerNameChange}
                        >
                             <option value="">Select Customer Name</option>
                            {rfpData.map((rfp) => (
                                <option key={rfp.customer_name} value={rfp.customer_name}>
                                    {rfp.customer_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="rfp_number">RFP Number</label>
                        <select
                            className="form-control"
                            id="rfp_number"
                            name="rfp_number"
                            value={selectedRfpNumber}
                            onChange={handleRfpNumberChange}
                        >
                            <option value="">Select RFP Number</option>
                            {rfpData.map((rfp) => (
                                <option key={rfp.rfp_number} value={rfp.rfp_number}>
                                    {rfp.rfp_number}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="project_number">Project Number</label>
                        <select
                            className="form-control"
                            id="project_number"
                            name="project_number"
                            value={selectedProjectNumber}
                            onChange={handleProjectNumberChange}
                        >
                            <option value="">Select Project Name</option>
                            {rfpData.map((rfp) => (
                                <option key={rfp.project_name} value={rfp.project_name}>
                                    {rfp.project_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="project_date">Due Date</label>
                        <select
                            className="form-control"
                            id="project_date"
                            name="project_date"
                            value={selectedProjectDate}
                            onChange={handleProjectDateChange}
                        >
                            <option value="">Select Due Date</option>
                            {rfpData.map((rfp)=> (
                                <option key={rfp.due_date} value={rfp.due_date}>
                                    {rfp.due_date}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className='container mt-4'>
                    
                        <div className='mb-4'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>RFP Number</th>
                                        <th>Customer Name</th>
                                        <th>Project Name</th>
                                        <th>Due Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(filteredRfpData.length > 0 ? filteredRfpData : rfpData).map((rfp, rfpIndex) => (
                                        <tr key={rfpIndex}>
                                            <td>{rfp.rfp_number}</td>
                                            <td>{rfp.customer_name}</td>
                                            <td>{rfp.project_name}</td>
                                            <td>{rfp.due_date}</td>
                                            <td>
                                            <button 
                                                onClick={() => handleQuotation(rfp)}  
                                                className='btn btn-primary'
                                            >
                                                Show Quotation
                                            </button>
                                            </td>   
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    
                    <div>
                        <Modal show={showModal} onHide={handleClose} size="xl">
                            <Modal.Header closeButton>
                                {/* <Modal.Title>Quotation for RFP {selectedRfp?.rfp_number}</Modal.Title> */}
                                <Modal.Title>
                                    {selectedRfp ? `Quotation for RFP ${selectedRfp.rfp_number}` : "Quotation"}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {selectedRfp ? (
                                    <div>
                                        {selectedRfp.products.map((product, productIndex) => (
                                            <div key={productIndex} className="mb-4">
                                                <h6>{product.name}</h6>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <th>Product Description</th>
                                                            <th>Qty</th>
                                                            <th>Price</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{product.name}</td>
                                                            <td>{product.description}</td>
                                                            <td>{product.quantity}</td>
                                                            <td>{product.targetPrice}</td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    name="qty"
                                                                    value={formValues[selectedRfp.rfp_number]?.[productIndex]?.qty || ''}
                                                                    onChange={(e) => handleInputChange(e, selectedRfp.rfp_number, productIndex)}
                                                                    className="form-control"
                                                                    placeholder="Qty"
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    name="price"
                                                                    value={formValues[selectedRfp.rfp_number]?.[productIndex]?.price || ''}
                                                                    onChange={(e) => handleInputChange(e, selectedRfp.rfp_number, productIndex)}
                                                                    className="form-control"
                                                                    placeholder="Price"
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    name="total"
                                                                    value={formValues[selectedRfp.rfp_number]?.[productIndex]?.total || ''}
                                                                    className="form-control"
                                                                    placeholder="Total"
                                                                    readOnly
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div>
                                                    <h6>Vendors for {product.name}:</h6>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Vendors</th>
                                                                <th>Quantity</th>
                                                                <th>Price</th>
                                                                <th>Remarks</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Vendor 1</td>
                                                                <td>{product.vendors[0].quantity}</td>
                                                                <td>{product.vendors[0].price}</td>
                                                                <td>{product.vendors[0].remarks}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Vendor 2</td>
                                                                <td>{product.vendors[1].quantity}</td>
                                                                <td>{product.vendors[1].price}</td>
                                                                <td>{product.vendors[1].remarks}</td>  
                                                            </tr>
                                                            <tr>
                                                                <td>Vendor 3</td>
                                                                <td>{product.vendors[2].quantity}</td>
                                                                <td>{product.vendors[2].price}</td>
                                                                <td>{product.vendors[2].remarks}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No data available.</p>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handleSubmit}>
                                    Sumbit
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Practise1;