import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Form4 from './Components/Form4';
import Form1 from './Components/Form1';
import Form2 from './Components/Form2';
import Form6 from './Components/Form6';
import Form5 from './Components/Form5';
import Practise1 from './Components/Practise1';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Form4 />} />
        <Route path='/Form1' element={<Form1 />} />
        <Route path='/Form2' element={<Form2 />} />
        <Route path='/Form3' element={<Form6 />} />
        <Route path='/Form5' element={<Form5 />} />
        <Route path='/Practise1' element={<Practise1 />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
