import React, { useState } from 'react';
import Navbar from './Navbar';

const Form6 = () => {
    const [formData, setFormData] = useState({
        rfp_no: '',
        select_entity: [], 
        select_item: [],
        due_date: ''
    });

    
    const [tablesData, setTablesData] = useState([]);

    const [dropdownOpen, setDropdownOpen] = useState(false); 

    const entityData = [
        { id: 1, name: 'Entity 1', contact: '123-456-7890', email: 'entity1@example.com' },
        { id: 2, name: 'Entity 2', contact: '234-567-8901', email: 'entity2@example.com' },
        { id: 3, name: 'Entity 3', contact: '345-678-9012', email: 'entity3@example.com' },
        { id: 4, name: 'Entity 4', contact: '456-789-0123', email: 'entity4@example.com' },
        { id: 5, name: 'Entity 5', contact: '567-890-1234', email: 'entity5@example.com' },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        if (name === 'rfp_no') {
            updateTablesData(value);
        }
    };

    const handleEntityChange = (entityId) => {
        setFormData((prevData) => {
            const newEntities = prevData.select_entity.includes(entityId)
                ? prevData.select_entity.filter(id => id !== entityId)
                : [...prevData.select_entity, entityId]; 

            return { ...prevData, select_entity: newEntities };
        });
    };

    const handleItemChange = (itemIndex) => {
        setFormData((prevData) => {
            const newSelectedItems = prevData.select_item.includes(itemIndex)
                ? prevData.select_item.filter(index => index !== itemIndex) 
                : [...prevData.select_item, itemIndex]; 
            
            return { ...prevData, select_item: newSelectedItems };
        });
    };
    

    const updateTablesData = (rfpNumber) => {
        let data = [];
        switch (rfpNumber) {
            case '1':
                data = [
                    { item: 'Item 1', description: 'Description 1', quantity: 10, price: 100 },
                    { item: 'Item 2', description: 'Description 2', quantity: 20, price: 200 }
                ];
                break;
            case '2':
                data = [
                    { item: 'Item 1', description: 'Description 1', quantity: 15, price: 150 },
                    { item: 'Item 2', description: 'Description 2', quantity: 25, price: 250 },
                    { item: 'Item 3', description: 'Description 3', quantity: 30, price: 300 }
                ];
                break;
            case '3':
                data = [
                    { item: 'Item 1', description: 'Description 1', quantity: 5, price: 50 },
                    { item: 'Item 2', description: 'Description 2', quantity: 10, price: 100 },
                    { item: 'Item 3', description: 'Description 3', quantity: 15, price: 150 },
                    { item: 'Item 4', description: 'Description 4', quantity: 20, price: 200 },
                    { item: 'Item 5', description: 'Description 5', quantity: 25, price: 250 }
                ];
                break;
            default:
                data = [];
                break;
        }
        setTablesData(data); 
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form data submitted", formData);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <>
            <Navbar />
            <div className='container pt-3'>
                <h2>RFQ FORM</h2>
                <form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
                    <div className='form-group'>
                        <label htmlFor='rfp_no'>RFP Number:</label>
                        <select
                            className="form-control"
                            id='rfp_no'
                            name='rfp_no'
                            value={formData.rfp_no}
                            onChange={handleChange}
                        >
                            <option value="">Select RFP</option>
                            <option value="1">RFP 1</option>
                            <option value="2">RFP 2</option>
                            <option value="3">RFP 3</option>
                        </select>
                    </div>

                    
                    <div className="mt-4">
                        {tablesData.length > 0 && (
                            <div>
                                <h3>Select Items for RFP {formData.rfp_no}</h3>
                                
                                <div className="table-responsive">
                                    <table className="table table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th>Select</th>
                                                <th>Product Name</th>
                                                <th>Product Description</th>
                                                <th>Product Quantity</th>
                                                <th>Target Product Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tablesData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            value={index}
                                                            checked={formData.select_item.includes(index)}
                                                            onChange={() => handleItemChange(index)}
                                                        />
                                                    </td>
                                                    <td>{item.item}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.price}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>

                    
                    <div className='form-group'>
                        <label>Select Entity:</label>
                        <div className="dropdown">
                            <button 
                                className="btn btn-secondary dropdown-toggle" 
                                type="button" 
                                onClick={toggleDropdown}
                                aria-expanded={dropdownOpen}
                            >
                                {formData.select_entity.length === 0 ? 'Select Entities' : `${formData.select_entity.length} Selected`}
                            </button>
                            {dropdownOpen && (
                                <div className="dropdown-menu" style={{ display: 'block' }}>
                                    {entityData.map(entity => (
                                        <div key={entity.id} className="dropdown-item form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={entity.id}
                                                checked={formData.select_entity.includes(entity.id)}
                                                onChange={() => handleEntityChange(entity.id)}
                                            />
                                            <label className="form-check-label">
                                                {entity.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    
                    {formData.select_entity.length > 0 && (
                        <div className="mt-4">
                            <h3>Contact Details</h3>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Entity Name</th>
                                        <th>Contact Number</th>
                                        <th>Email ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.select_entity.map((entityId) => {
                                        const selectedEntity = entityData.find(entity => entity.id === entityId);
                                        return (
                                            <tr key={selectedEntity.id}>
                                                <td>{selectedEntity.name}</td>
                                                <td>{selectedEntity.contact}</td>
                                                <td>{selectedEntity.email}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <div className="form-group">
                        <label htmlFor="due_date">Due Date:</label>
                        <input
                            type="date"
                            id="due_date"
                            name="due_date"
                            value={formData.due_date}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>

                    <button type='submit' className='btn btn-primary mt-3'>Submit</button>
                </form>
            </div>
        </>
    );
};

export default Form6;
